<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <!--header-->
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-if="$havePermission('CREATE_DOLLARS')" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info" @click="$refs.modal.toggle()">
                    <feather-icon size="16" icon="DollarSignIcon"/>
                    قیمت جدید
                </b-button>
            </div>

            <!--sidebar-filter-->
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--addNewPriceModal-->
            <b-modal
                    ref="modal"
                    cancel-variant="outline-secondary"
                    ok-title="ثبت قیمت"
                    cancel-title="لغو"
                    centered
                    title="قیمت جدید تتر"
                    v-model="modal"
                    @ok.prevent="submit(price)"
            >
                <b-form @submit.prevent="submit(price)">
                    <b-form-group label="ثبت قیمت روز تتر">
                        <cleave
                                class="form-control"
                                :raw="false"
                                :options="{numeral: true}"
                                placeholder="به تومان"
                                v-model="price"
                        />
                    </b-form-group>
                    <!--                <b-form-group label="رمز دوم امنیتی">-->
                    <!--                    <b-form-input-->
                    <!--                            type="password"-->
                    <!--                            placeholder="رمز"-->
                    <!--                    />-->
                    <!--                </b-form-group>-->
                </b-form>
            </b-modal>

            <!--priceTable-->
            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        class="text-nowrap"
                        style="min-height : 235px"
                >

                    <template #cell(price)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.price) }}
                        </span>
                    </template>

                    <!--                    <template #cell(dollarDeals)="data">-->
                    <!--                        <span dir="ltr">-->
                    <!--                            {{data.item.dollarDeals.toLocaleString()}}-->
                    <!--                        </span>-->
                    <!--                    </template>-->

                    <!--                    <template #cell(tomanDeals)="data">-->
                    <!--                        <span dir="ltr">-->
                    <!--                            {{data.item.tomanDeals.toLocaleString()}}-->
                    <!--                        </span>-->
                    <!--                    </template>-->

                    <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at, 'faTime') }}
                        </span>
                    </template>

                    <template #cell(percent)="data">
                        <b-badge
                                pill
                                :variant="'light-'+changedPercentVariant(data.item.percent)"
                                class="text-capitalize"
                                dir="ltr"
                        >
                            {{ data.item.percent * 100 }}
                        </b-badge>
                        <feather-icon
                                :icon="changedPercentIcon(data.item.percent)"
                                size="18"
                                class="ml-50"
                                :class="`text-${changedPercentVariant(data.item.percent)}`"
                        />
                    </template>

                    <template #cell(action)="{index}">
                        <template
                                v-if="$havePermission('LIST_ORDERS') ||
                                 $havePermission('LIST_TRADES')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item v-if="$havePermission('LIST_ORDERS')"
                                                 :to="{ name: 'Orders', query: { dollar_id: index+1 } }">
                                    <feather-icon icon="ShoppingBagIcon" :badge="5"
                                                  badge-classes="badge-info test-badge"/>
                                    <span class="align-middle ml-50">سفارشات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_TRADES')"
                                                 :to="{ name: 'Deals', query: { dollar_id: index+1 } }">
                                    <feather-icon icon="BriefcaseIcon" :badge="5"
                                                  badge-classes="badge-info test-badge"/>
                                    <span class="align-middle ml-50">معاملات</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>
                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BModal,
    VBModal,
    BForm,
    BFormGroup,
    // BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BOverlay,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component'
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
    name: 'DollarPrice',
    components: {
        NotAllowed,
        SearchAndFilter,
        BPagination,
        BCard,
        BForm,
        BFormGroup,
        // BFormInput,
        BModal,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        vSelect,
        Cleave,
        BOverlay,
    },
    directives: {
        'b-modal': VBModal,
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 200,
        modal: false,
        sortBy: '',
        price: '',
        isSortDirDesc: '',
        userData: [],
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        columns: [
            {
                label: 'قیمت واحد (تومان)',
                key: 'price',
                sortable: true,
                searchType: 'number'
            },
            // {
            //     label: 'معاملات انجام شده (دلار)',
            //     key: 'dollarDeals',
            //     sortable: true,
            //     searchType: 'number'
            // },
            // {
            //     label: 'معاملات انجام شده (تومان)',
            //     key: 'tomanDeals',
            //     sortable: true,
            //     searchType: 'number'
            // },
            {
                label: 'تاریخ ثبت قیمت',
                key: 'created_at',
                sortable: true,
                searchType: 'date'
            },
            {
                label: 'تغییر',
                key: 'percent',
                sortable: true,
                searchType: 'number',
            },
            // {
            //     label: 'جزییات',
            //     key: 'action'
            // },
        ],
    }),
    computed: {
        changedPercentVariant() {
            return e => e < 0 ? 'danger' : e > 0 ? 'success' : 'secondary';
        },
        changedPercentIcon() {
            return e => e < 0 ? 'ArrowDownCircleIcon' : e > 0 ? 'ArrowUpCircleIcon' : 'CircleIcon';
        },
    },
    methods: {
        async submit(e) {
            e = e.replace(/,/ig, '')
            this.state.loading = true
            await this.$axios.post('/dollar-prices', {price: e})
            this.state.loading = false
            this.modal = false
            this.getData(1, this.perPage)
        },
        sort(e) {

            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            })

            this.getData(1, this.perPage)

        },
        async getData(page, perPage) {

            const queryParams = {
                size: perPage,
                page: page,
                ...this.$route.query
            }
            this.state.loading = true
            const res = await this.$axios('/dollar-prices', {params: queryParams})
            this.state.loading = false
            this.items = res.data.data

            this.currentPage = res.data.current_page
            this.rows = res.data.total
        },
    },
    created() {
        this.getData()
    }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
